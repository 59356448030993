.badge {

    font-size: $font-size-base * 1;

    &.badge-info-original {
        background: lighten($tealOriginal, 40);
        border: solid 1px $tealOriginal;
        // color: $white;
    }
    
}