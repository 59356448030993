.noScroll {
    overflow: hidden !important;
}

.cursor-pointer {
    cursor: pointer;
}

.progress-gradient.progress {

    &.finished {
        .progress-bar {
            background: $green !important
        }
    }

    .progress-bar {
        background: linear-gradient(to right, $red 5%, $orange 20%, $green 60%) !important;
    }

} 

.background.full-background {
    overflow: scroll;
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 1050;
    background: $body-bg;
    padding: $spacer * 3;
}

.list-style-none {
    list-style: none;
}

ul.hover-click {
    li {
        &:hover {
            background: $gray-200;
            cursor: pointer;
        }
    }
}

.dz-message {
    z-index: 30;
}

.hc-launch {
    position: relative;
    top: 6px;
    color: $tealOriginal;
    cursor: pointer;
}


.text-underline:hover {
    text-decoration: underline;
}




.archk-v2-sidebar-full {
    .archk-v2-sidebar {
        transition: none;
        width: 300px;
    }

    .archk-v2-main {
        margin-left: 300px;
    }
}


.archk-v2-sidebar {
    width: 94px;
    overflow: hidden;
    // transition: all .2s;
    transition: none;

    position: fixed;
    top: 0;
    left: 0;
    bottom: 0;
    z-index: 10;

    background: $white;

    .row {
        margin-left: 0;
        margin-right: 0;
    }

    .archk-v2-sidebar-left {

        

        border-right: solid 1px $gray-200;
        height: 100vh;

        ul {
            padding-left: 0;

            li {
                list-style: none;
                text-align: center;
                margin-top: $spacer;
                margin-bottom: $spacer;
                padding-top: $spacer;
                padding-bottom: $spacer;
                cursor: pointer !important;
                border-radius: $border-radius;

                &.active {
                    background: linear-gradient(87deg, $success 0, adjust-hue($success, 25%) 100%) !important;
                    color: $white;
                    transition: all .2s;
                    border-radius: $border-radius;
                    // outline: solid 8px $info;
                }

                &:hover {
                    background: linear-gradient(87deg, $success 0, adjust-hue($success, 25%) 100%) !important;
                    color: $white;
                    transition: all .2s;
                    border-radius: $border-radius;
                }

                .archk-v2-sidebar-left-icon {
                    font-size: $font-size-base * 1.2;
                }
    
                .archk-v2-sidebar-left-text {
                    font-size: $font-size-base;
                }
            }

        }

        

    }

    .archk-v2-sidebar-right {

        padding-left: 0;
        padding-right: 0;

        .archk-v2-sidebar-right-title {
            font-size: $font-size-base * 1.7;
            font-weight: bold;


            padding-top: 16px;
            padding-bottom: 16px;

            padding-left: $spacer * 2;
                padding-right: $spacer * 2;
        }

        ul {

            padding-left: 0;

            li {
                list-style: none;
                text-align: center;

                padding-top: $spacer * 1.5;
                padding-bottom: $spacer * 1.5;

                padding-left: $spacer * 2;
                padding-right: $spacer * 2;

                font-weight: bold;

                border-bottom: solid 1px $gray-200;

                font-size: $font-size-base *1.1;

                // text-transform: uppercase;

                &.active {
                    background: $gray-200;
                    color: $info;
                    border-right: solid 5px $info;
                }

                .archk-v2-sidebar-right-icon {
                    display: inline-block;
                    width: 40px;
                }
            }
        }

    }
}

.archk-v2-main {
    margin-left: 94px;
    padding: $spacer * 2;
    // transition: all .2s;

    #archk-v2-faq-search {
        padding-left: 37px !important;
    }

    button.btn {
        &.btn-lg {
            padding-left: $spacer * 3;
            padding-right: $spacer * 3;
            padding-top: $spacer * 1.5;
            padding-bottom: $spacer * 1.5;
        }

        &.btn-info {
            background: $info;
        }
    }

    .badge {
        font-weight: normal;
        text-transform: none;

        padding-left: $spacer * 2;
        padding-right: $spacer * 2;
        padding-top: $spacer 1;
        padding-top: $spacer 1;
        font-size: $font-size-base;

        min-width: 120px;

        &.bg-success {
            border: solid 1px $success !important;
            background: lighten($success, 15) !important;
        }
        &.bg-danger {
            border: solid 1px $danger !important;
            background: lighten($danger, 15) !important;
        }
        &.bg-info {
            border: solid 1px $info !important;
            background: lighten($info, 15) !important;
        }
        &.bg-warning {
            border: solid 1px $warning !important;
            background: lighten($warning, 15) !important;
        }
    }

    table.table {

        border: solid 1px $gray-200;
        border-radius: $border-radius-lg !important;
        // overflow: hidden;

        thead {



            tr {
                background: lighten($green, 30)
            }
        }

        tr {
            th {
                font-size: $font-size-base;
                text-transform: none;
            }
            td {
                font-size: $font-size-base;
            }
        }
    }

    .archk-v2-internal-sidebar {

        margin-left: 0;
        margin-right: 0;

        .archk-v2-internal-sidebar-left {

            padding-left: 0;
            padding-right: 0;

            ul {
                padding-left: 0;
                margin-left: 0;
                padding-right: 0;
                margin-right: 0;
                margin-bottom: 0;

                li {
                    list-style: none;

                    padding-top: $spacer * 1;
                    padding-bottom: $spacer * 1;

                    padding-left: $spacer * 2;
                    padding-right: $spacer * 2;

                    border-bottom: solid 1px $gray-200;

                    &.active {
                        background: $gray-200;
                        border-right: solid 5px $info;
                    }

                    .archk-v2-internal-sidebar-icon {
                        display: inline-block;
                        width: 30px;
                    }

                }

            }

        }

    }

}

.custom-shadow-1 {
    box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
    -webkit-box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
}
.custom-shadow-2 {
    box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
    -webkit-box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
}
.custom-shadow-3 {
    box-shadow: rgba(0, 0, 0, 0.05) 0px 6px 24px 0px, rgba(0, 0, 0, 0.08) 0px 0px 0px 1px;
    -webkit-box-shadow: rgba(0, 0, 0, 0.05) 0px 6px 24px 0px, rgba(0, 0, 0, 0.08) 0px 0px 0px 1px;
}
.custom-shadow-4 {
    box-shadow: rgba(33, 35, 38, 0.1) 0px 10px 10px -10px;
    -webkit-box-shadow: rgba(33, 35, 38, 0.1) 0px 10px 10px -10px;
}

p {
    font-size: $font-size-base;
    // font-weight: bold;
}

.archk-v2-name-circle {
    background: $info;
    color: $white;
    border-radius: 100%;
    display: inline-block;
    width: 38px;
    margin-right: $spacer * 1.7;
    text-align: center;
}

.archk-v2-header-icon {
    margin-left: $spacer * 2.5;

    font-size: $font-size-base * 1.3;
}

.archk-v2-onboarding {

    .archk-v2-doc-select-card-body {
        display: flex;
        justify-content: center;
        align-items: center;
        height: 100px;
        width: 100%;
    }

    .archk-v2-onboarding-section {
        img {
            height: 150px;
        }
        p {
            display: flex;
            justify-content: center;
            align-items: center;
            height: 75px;
            width: 100%;

        }
    }

}




.archk-chat {

  

    .archk-chat-card-body {
        max-height: calc(100vh - 350px);
        overflow: auto;
        position: relative;
    }

    .archk-chat-card {
        background: #E0EFFF;
    }

    .archk-chat-avatar {
        border-radius: 100%;
        width: 50px;
    }

    .archk-chat-input-wrapper {

        bottom: 0;
        left: 0;
        right: 0;
        z-index: 10;

        i {
            position: absolute;
            top: 18px;
            right: 24px;
            cursor: pointer;
        }
    }

    .archk-chat-message {

        width: 500px;
        max-width: 80%;
        margin-bottom: $spacer;

        .card {
            margin-bottom: 6px;

        }

        .col-auto {
            width: 55px;
        }

        &.archk-chat-message-outgoing {
            .archk-chat-avatar {
                position: relative;
                right: 16px;
            }
            
        }
        &.archk-chat-message-incoming {
            .card {
                background: #16416D;
                color: $white;
            }
        }

    }
   
}

.archk-v2-mobile-nav {
    position: fixed;
    display: none;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 1000;
    background: $white;
    text-align: center;  
    border-top: solid 1px $gray-200;

    .col {
        padding-left: 0;
        padding-right: 0    ;
    }

    i {
        margin-top: $spacer;
        // margin-bottom: $spacer * .5;
        font-size: 20px;

        padding-top: $spacer * 1;
        padding-bottom: $spacer * 1;
        padding-left: $spacer * 1;
        padding-right: $spacer * 1;
        border-radius: $border-radius;
    }

    a {
       
        display: inline-block;
        font-size: 12px;
        margin-bottom: $spacer;

    }

    .active {
        i {
            background: $success;
            color: $info;
        }
    }
}

// .archk-v2-header {
//     margin-left: 300px
// }

@include media-breakpoint-down(md) {

    .archk-v2-mobile-nav {
        display: block;
    }
 
    .archk-v2-sidebar {
        display: none !important;

    }
    .archk-v2-main {
        margin-left: 0 !important;
        margin-bottom: 50px;
    }

    .archk-v2-header {

        margin: -$spacer * 2;

        img {
            width: 100% !important;
            margin-bottom: 10px;
        }

        h2 {
            font-size: 20px !important;
        }

        .archk-v2-name-circle {
            width: 30px !important;
            margin-right: 8px;
        }

        .archk-v2-header-icon {
            // margin-left: $spacer * 1.5 !important;
        }
    }
}