//this is the background water mark 
$height: 50vh;
.wave {
    position: fixed;
    left: 0;
    bottom: 0;
    height: $height;
    width: 100%;
    background: white;
    opacity: 0.05;
}
.wave:before {
    content: "";
    display: block;
    position: absolute;
    border-radius: 100% 50%;
    width: 100%;
    height: $height * 1.2;
    background-color: #aaa;
    box-shadow: rgba(0, 0, 0, 0.047) 0 2.8px 1.3px, rgba(0, 0, 0, 0.086) 0 6.7px 3.1px, rgba(0, 0, 0, 0.12) 0 12.5px 5.8px, rgba(0, 0, 0, 0.15) 0 22.3px 10.3px, rgba(0, 0, 0, 0.173) 0 41.8px 19.2px, rgba(0, 0, 0, 0.31) 0 100px 46px;

    right: -5px;
    top: 40px;
}
.wave:after {
    content: "";
    display: block;
    position: absolute;
    border-radius: 100% 50%;
    width: 95%;
    height: $height;
    background-color: white;
    left: 0;
    top: 27px;
}

.bg-gradient-info-dark {
    background: #0D2741;
    background: linear-gradient(213.84deg, #2160A2 0.72%, #0D2741 86.46%);

}

.bg-info-light {
    background-color: #2D80D5 !important;
}
.bg-info-light-2 {
    background-color: #2160A2 !important;
}